@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import 'react-lazy-load-image-component/src/effects/blur.css';

html, body {
  overflow-x: hidden !important;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

nav {
  z-index: 99 !important;
}

.swiper-button-prev {
  color: white !important;
}

.swiper-button-next {
  color: white !important;
}

.swiper-pagination-bullet {
  display: none !important;
}

.swiper-wrapper {
  display: flex !important;
  align-items: center !important;
}

/* .swiper-slide {
  width: fit-content !important;
  margin: auto;
} */

.new-box {
  margin-top: -180px;
}

.animation-wrapper {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.animation-wrapper.animate {
  opacity: 1;
  transform: translateY(0);
}

.rounded-portfolio {
  border-bottom-left-radius: 180px;
}

@keyframes slideInFromBottom {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1023px) {
  .new-box {
    display: none !important;
  }
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .slide-content {
    height: auto;
  }
  .image-slider {
    object-fit: fill;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
  .slide-content {
    height: auto;
  }
  .image-slider {
    object-fit: contain;
  }
}

/* @media screen {
  .slide-content {
    height: auto;
  }
  .image-slider {
    object-fit: fill;
  }
} */